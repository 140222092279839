@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;500;700&family=Courier+Prime:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

p, a, li, h1, h2, h3, h4, button, input, label, select {
  font-family: 'Outfit', sans-serif;
} 

.nav-button {
  transition: all ease 200ms;
}

.nav-button .icon {
  color: #12165B;
  transition: all ease 200ms;
}

.nav-button:hover {
  background-color: #0148fd16;
}

.nav-button:hover .icon {
  color: #0147FD;
}

.nav-button.active:hover .icon {
  color: #0147FD;
}

.nav-button.active {
  background-color: #f62a0125;
  color: #F62B01;
}

.nav-button.active .icon {
  color: #F62B01;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 4px;
  border: 4px solid #5CD89F;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5CD89F transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.print-class {
  /* padding: 20px; */
  margin: 5mm 5mm 5mm 5mm;
}

.print-class .print-white-bg {
  background-color: white !important;
}

.print-class .print-flex-row {
  display: flex;
  flex-direction: row;
}

.print-class .print-underline {
  border-bottom: 1px solid #05194C;
  border-radius:0 !important
}

.print-class .print-block {
  display: inline-block !important;
}
